/**
 * We initialise our App Shell asynchronously via a dynamic import. This enables us to use shared
 * dependencies with Module Federation.
 *
 * Without this abstraction, all shared dependencies would need to be eagerly consumed, which blows
 * up the async nature of our App Shell startup sequence.
 *
 * @see https://webpack.js.org/concepts/module-federation/#uncaught-error-shared-module-is-not-available-for-eager-consumption
 * @see https://blog.devgenius.io/module-federation-advanced-api-inwebpack-5-0-0-beta-17-71cd4d42e534#7ebb (Point 1.0).
 */
import("./bootstrap/main");
